import React from 'react';
import raf from 'rc-util/lib/raf';
/**
 * When click on the label,
 * the event will be stopped to prevent the label from being clicked twice.
 * label click -> input click -> label click again
 */
export default function useBubbleLock(onOriginInputClick) {
    const labelClickLockRef = React.useRef(null);
    const clearLock = () => {
        raf.cancel(labelClickLockRef.current);
        labelClickLockRef.current = null;
    };
    const onLabelClick = () => {
        clearLock();
        labelClickLockRef.current = raf(() => {
            labelClickLockRef.current = null;
        });
    };
    const onInputClick = (e) => {
        if (labelClickLockRef.current) {
            e.stopPropagation();
            clearLock();
        }
        onOriginInputClick === null || onOriginInputClick === void 0 ? void 0 : onOriginInputClick(e);
    };
    return [onLabelClick, onInputClick];
}
